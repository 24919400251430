.ref-table {
border:0px;
border-collapse:collapse;
border-spacing:0px;
}

td.ref-table-cell {
padding:0px;
border-width:0px;
margin:0px;

}

tr.ref-table-row {
   padding:0px;
   border-width:0px;
   margin:0px;
}

h5.ui.header {
   margin: 0px 0px 0px 0px;

}