.ui.segment.sa-container {
      padding: 0px 0px 0px 0px;

}

.ui.bottom.attached.segment.active.tab {
     padding: 1px 1px 1px 1px;
}

.ui.vertical.menu{
    width: 150px;
    min-width: 150px;
    max-width: 150px;

}

.four.wide.column {
        width: 150px;
        min-width: 150px;
        max-width: 150px;


}